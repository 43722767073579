<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <div
                class="d-flex flex-row align-center"
                style="gap: 1rem;">
                <h3 class="primary--text font-weight-bold text-capitalize">
                  ระดับ {{ rank }}
                </h3>
                <div
                  class="rounded-pill px-4 py-2 d-flex flex-row align-center justify-center"
                  :class="`rank-${rank}`">
                  <v-icon color="white">
                    mdi-crown-outline
                  </v-icon>
                  <span class="font-weight-bold text-capitalize white--text">
                    {{ rank }}
                  </span>
                </div>
              </div>
              <v-divider class="mt-3" />
            </v-col>
            <v-col cols="12">
              <p>
                เงื่อนไขการได้ระดับ
              </p>
              <v-radio-group
                v-model="formData.condition"
                hide-details>
                <v-radio
                  v-for="cond in conditions"
                  :key="cond.value"
                  :value="cond.value">
                  <template #label>
                    <div
                      v-if="cond.value === 'point'"
                      class="d-flex flex-row align-center">
                      <span class="mr-2">
                        {{ cond.text }}
                      </span>
                      <v-text-field
                        v-model.number="formData.maxPoint"
                        :rules="formData.condition === 'point' ? textBoxRules : []"
                        type="number"
                        color="primary"
                        outlined
                        dense
                        required
                        hide-details />
                    </div>
                    <span v-else>
                      {{ cond.text }}
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p>
                สิทธิพิเศษ
              </p>
              <div
                v-for="(_, i) in formData.privileges"
                :key="i"
                class="d-flex flex-row items-center"
                style="gap: 0.5rem;">
                <v-autocomplete
                  v-model="formData.privileges[i]"
                  :items="privileges"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  placeholder="โปรดเลือกสิทธิพิเศษ"
                  disabled />
                <v-btn
                  v-if="formData.privileges.length > 1"
                  color="error"
                  icon
                  @click="removeItem(i)">
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  disabled
                  style="width: fit-content; background-color: white;"
                  @click="addItem()">
                  <v-icon>
                    mdi-plus-circle-outline
                  </v-icon>
                  <span>
                    เพิ่มสิทธิพิเศษ
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import MemberRanksProvider from '@/resources/member-ranks.provider'

const MemberRanksService = new MemberRanksProvider()

export default {
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    conditions: [
      {
        text: 'สมัครสมาชิกครั้งแรก',
        value: 'new_member'
      },
      {
        text: 'คะแนนสะสม',
        value: 'point'
      }
    ],
    privileges: [],
    formData: {
      name: '',
      condition: 'new_member',
      maxPoint: 0,
      privileges: ['']
    }
  }),
  computed: {
    rank () {
      return this.$route.params?.rank || 'ไม่ทราบ'
    }
  },
  mounted () {
    this.getItemByName()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SettingsMember' })
    },
    addItem () {
      this.formData.privileges.push('')
    },
    removeItem (index) {
      this.formData.privileges.splice(index, 1)
    },
    async getItemByName () {
      try {
        this.loading = true

        const { data } = await MemberRanksService.getItemByName(this.rank)

        this.formData = {
          ...data,
          privileges: data.privileges.length ? data.privileges : ['']
        }
      } catch (error) {
        console.error('getItemByName', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        await MemberRanksService.updateItemById({
          ...this.formData,
          privileges: this.formData.privileges.filter((v) => !!v)
        })

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsMember' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
